import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Header from '../components/header';
import Footer from '../components/footer';
import MainSection from '../components/MainSection';

const LoginPage = () => {
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            navigate('/home');
        }
    }, [navigate]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePassClose = () => setShowPasswordModal(false);
    const handlePassShow = () => setShowPasswordModal(true);

    const validateInput = (emailOrPhone, password) => {
        if (emailOrPhone.length === 11 && password !== "") {
            if (password.length >= 7) {
                return true; // Valid number and password
            } else {
                setError('Password is incorrect. It must be at least 7 characters long.');
                return false;
            }
        } else if (emailOrPhone.includes('@') && password !== "") {
            if (emailOrPhone.includes('.com') || emailOrPhone.includes('.Com') || emailOrPhone.includes('.COM')) {
                if (emailOrPhone.includes('gmail') || emailOrPhone.includes('Gmail') || emailOrPhone.includes('GMAIL')) {
                    if (password.length >= 7) {
                        return true; // Valid email and password
                    } else {
                        setError('Password is incorrect. It must be at least 7 characters long.');
                        return false;
                    }
                } else {
                    setError('Invalid email domain.');
                    return false;
                }
            } else {
                setError('Invalid email format.');
                return false;
            }
        } else {
            if (emailOrPhone.length >= 11 || emailOrPhone.includes('@')) {
                setError('Invalid number or email format.');
            } else if (password === "" || password.length < 7) {
                setError('Password is required and must be at least 7 characters long.');
            } else if (!emailOrPhone.includes('@')){
                setError('Invalid number or email format.');
            }
            return false;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!validateInput(emailOrPhone, password)) {
            setLoading(false);
            handleShow();
            return;
        }

        const userField = emailOrPhone.length === 11 && !emailOrPhone.includes('@') ? 'user_number' : 'user_email';

        const params = new URLSearchParams();
        params.append('username', emailOrPhone);
        params.append('real_pass', password);
        params.append('fieldName', userField);
        params.append('table_name', 'users');

        try {
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 20000); // 20 seconds timeout

            const response = await axios.post('https://api.betprowallet.pk/loginUserApi_ft.php', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                cancelToken: source.token,
            });

            clearTimeout(timeout);


            if (response.data.message === '0 results') {
                setError('Your email or phone number, or password is incorrect. Please try again.');
                handleShow();
            } else {


                

                // Check if response starts with "0 results"
                if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
                    setError('User not found. Please try again.');
                    handleShow();
                } else {



                    if(response.data[0].user_blocked === '0'){

                    localStorage.removeItem('user');

                    const user = {
                        name: response.data[0]?.user_fullname || 'User',
                        bp_username: response.data[0]?.user_betpro_username || '',
                        bp_pass: response.data[0]?.user_betpro_password || '',
                        email: response.data[0]?.user_email || '',
                        password: '',
                        number: response.data[0]?.user_whatsapp || 'N/A',
                        userID: response.data[0]?.user_id || 'N/A',
                        userActive: response.data[0]?.user_active || 0,
                    };




                    localStorage.setItem('user', JSON.stringify(user));
                    navigate('/home');

                } else {

                    setError('User not found. Your email, phone number or password is incorrect.');
                    handleShow();

                }


                   

                }







            }

        } catch (error) {
            if (axios.isCancel(error)) {
                setError('Request timed out. Please try again.');
            } else {
                setError('Please check your internet connection...');
            }
            handleShow();

            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        handlePassClose();
        console.log('Password reset email should be sent here.');
    };

    return (
        <div>
            <Header />
            <div className="login-wrapper2">
                <div className="main-login">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-md-12">
                                <div className="left-login-box">
                                    <div className="fieldset"

                                        style={{
                                            padding: '10px 0px',
                                        }} >
                                        <div className="title mb-5">
                                            <h2>Login to Betpro wallet</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <div className="inputs field-container">
                                                    <form onSubmit={handleSubmit}>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            className="mb-4"
                                                            placeholder="Email or phone number"
                                                            value={emailOrPhone}
                                                            onChange={(e) => setEmailOrPhone(e.target.value)}
                                                            required
                                                        />
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className="mb-4"
                                                            placeholder="Enter password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />
                                                        <button type="submit" className="btn-signin mb-3" disabled={loading}>
                                                            {loading ? 'Loading...' : 'Sign in'}
                                                        </button>
                                                    </form>
                                                    <div className="forgot-password">
                                                        <a onClick={handlePassShow}>Forgot password?</a>
                                                    </div>
                                                    <a href="/signup" className="signup mb-3">
                                                        Don't have an account?
                                                    </a>
                                                    <button className="btn-create-account mb-3" onClick={() => navigate('/signup')}>
                                                        Create account
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <Modal.Title>Sign in Failed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{error}</p>
                    <hr />
                    <p onClick={handleClose} className="model-ok">Ok</p>
                </Modal.Body>
            </Modal>

            <Modal show={showPasswordModal} onHide={handlePassClose} centered>
                <Modal.Header >
                    <Modal.Title >Forgot Password?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You will receive instructions for resetting your password.</p>
                    <form onSubmit={handleForgotPassword}>
                        <div className='forgotinputs'>
                            <input type="text" placeholder="Email or phone number" required />
                            <button type="submit" className="btn-signin" style={{ marginTop: "15px" }}>Send my Password</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <MainSection />
            <Footer />
        </div>
    );
};

export default LoginPage;
