import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Nav from '../components/Navigation';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
const Transactionspage = () => {


  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);

  const navigate = useNavigate();
  useEffect(() => {

    if (!user) {
      navigate('/');
    }

    checkDepositsAndWithdraws(parsedUser.userID, setTransactions);

  }, [navigate]);

  // const transactions = [
  //   {
  //     date_time: "10-10-2023 02:23 AM",
  //     amount: 1000.00,
  //     payment_method: "easypaisa",
  //     status: "Accepted",
  //   },
  //   {
  //     date_time: "2023-11-15 11:59 PM",
  //     amount: 500.50,
  //     payment_method: "credit card",
  //     status: "Pending",
  //   },
  //   {
  //     date_time: "2023-12-01 08:00 AM",
  //     amount: 250.00,
  //     payment_method: "debit card",
  //     status: "Completed",
  //   },
  //   {
  //     date_time: "2024-01-10 03:15 PM",
  //     amount: 78.25,
  //     payment_method: "cash",
  //     status: "Processed",
  //   },
  //   {
  //     date_time: "2024-02-14 07:42 PM",
  //     amount: 1500.75,
  //     payment_method: "bank transfer",
  //     status: "Successful",
  //   },
  // ];


  const [transactions, setTransactions] = useState([]);



  // Function to check for pending deposits
  const checkDepositsAndWithdraws = async (userName, setTransactions) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);


      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_fetch_pending_deposits_withdraw.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);




      if (response.data) {





        const tempTransactions = [];

        response.data.map((item) => {
          tempTransactions.push({
            date_time: item.date_and_time,
            amount: item.amount,
            payment_method: item.bank_name,
            status: item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Rejected',
            transactionsType: item.transaction_type === 2 ? 'Withdraw' : 'Deposit',
          });
        });



        console.log(tempTransactions);

        setTransactions(tempTransactions);


        if (tempTransactions.length === 0) {
          setMsg('No transaction found.');

          handleShow();

        }






      }








    } catch (error) {
      if (axios.isCancel(error)) {

      } else {

      }


    }
  };

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolling) {
      setIsScrolling(true); // User started scrolling
    } else if (e.target.scrollTop === 0 && isScrolling) {
      setIsScrolling(false); // User stopped scrolling
    }
  };

  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');

  const handleShow = () => setShow(true);

  const handleClose = function () {
    setShow(false);
    navigate('/');

  };


  return (
    <div>
      <Header />

      {!(transactions.length === 0) && (
        <>
      <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
            <form method="post" action=" " >
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset2">
                      <div className="title mb-5">
                        <h2 className="mt-3">Transactions</h2>

                        <div
                          className="scrollable-transactions"
                          onScroll={handleScroll}
                          style={{
                            maxHeight: '900px', // Adjust the height based on your design
                            overflowY: 'auto',
                            transition: 'transform 0.3s ease-in-out', // For smooth animations
                          }}
                        >
                          {transactions.map((transaction, index) => (
                            <div key={index} className="account2 mt-3">
                              <data style={{ fontSize: '12px' }}>
                                {`${transaction.date_time.split(' ')[0].split('-').reverse().join('-')}`} |
                                {" " + new Date(transaction.date_time).toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true
                                })}
                              </data>
                              <p style={{ margin: '0' }} className="mt-1">
                                {transaction.transactionsType} accepted in the amount of {transaction.amount.toFixed(2)} PKR
                              </p>
                              <p>via {transaction.payment_method} ({transaction.status})</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      </>
      )};


      <Nav />

      {/* Modal for messages */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>

      </Modal>

    </div>
  )
}

export default Transactionspage
