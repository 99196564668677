import React from 'react';
import apk from '../assets/images/apk.png';
import google from '../assets/images/google.png';
import easypaisa from '../assets/images/mob/easypaisa.png';
import jazzcash from '../assets/images/mob/jazzcash.png';
import bank from '../assets/images/mob/bank.png';
import bitcoin from '../assets/images/mob/bitcoin.png';
import '../assets/css/style.css';

function Footer() {
  return (
    <div className="footer-wrapper">
    <div className="">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-6 ">
          <h2 className="footer-hed">BetproWallet.pk</h2>
          <div className="row">
            <div className="col-md-4 col-sm-12 mt-5">
              <div className="image-wrapper-left">
              <img src={apk} alt='APK img' />
              </div>
            </div>
            <div className="col-md-8 col-sm-12 mt-5">
              <div className="image-wrapper-left">
              <img src={google} alt='play store img' />
              </div>
            </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
        <h2 className="footer-hed" >Payment Methods</h2>
          <div className="row">
            <div className="col-md-3 col-sm-6 mt-5"> <div className="image-wrapper-right">
            <img src={easypaisa} alt='easypaisa img' />
              </div></div>
            <div className="col-md-3 col-sm-6 mt-5"> <div className="image-wrapper-right">
            <img src={jazzcash} alt='jazzcash img' />
              </div></div>
            <div className="col-md-3 col-sm-6 mt-5"> <div className="image-wrapper-right">
            <img src={bank} alt='bank img' />
              </div></div>
            <div className="col-md-3 col-sm-6 mt-5"> <div className="image-wrapper-right">
            <img src={bitcoin} alt='bitcoin img' />
              </div></div>
          </div>

        </div>
      </div>
      <hr />
      <div className="row">
                    <div className="col-md-12 text-center cpoyright">
                        <p>Copyright © 2024 Developed by Betpro Exchange</p>
                    </div>
                </div>
    </div>
  </div>
 
  );
}

export default Footer;
